export enum TransactionType {
  // Normal purchase transaction
  PURCHASE = "PURCHASE",
  // balance is added to the account, such as renewal of an account or creation of an account
  TOP_UP = "TOP_UP",
  // Sometimes the transaction needs to be adjusted, some fees etc...
  ADJUSTMENT = "ADJUSTMENT",
  // When money getting back into the account
  REFUND = "REFUND",
  // On renewal we want to reset the balance, unused amount is getting expired
  BALANCE_EXPIRE = "BALANCE_EXPIRE",
  // at some point we might want to retire an older transactions to keep the size of data reasonable
  // then we might want to make some aggregations on the accounts
  AGGREGATION = "AGGREGATION",
  // For peer to peer payments being sent/received
  PAYMENT = "PAYMENT"
}
