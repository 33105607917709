import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import { Tooltip } from "@mui/material";

export const RewardAllocationLogoTooltip = () => {
  return (
    <Tooltip title="Reward allocation account">
      <EmojiEventsIcon sx={{ ml: 0.5 }} />
    </Tooltip>
  );
};
