import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Box,
  InputAdornment,
  TextField,
  Typography
} from "@mui/material";
import { FC, useState } from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { EmployeeAccount, Pagination } from "../models";
import { ReducedEmployee } from "../models/employees/reducedEmployee";
import { callApi } from "../utils/fetchData";
import { formatCurrency } from "../utils/formatters";
import { useConfig } from "../utils/useConfig";
import { useCustomQuery, useData } from "../utils/useData";

export const SendPayment: FC<{ account: EmployeeAccount }> = ({ account }) => {
  const { userInfoData, accounts, transactions } = useData();
  const { getToken } = useKindeAuth();
  const { config } = useConfig();
  const navigate = useNavigate();

  const [error, setError] = useState<string | undefined>();
  const [selectedEmployee, setSelectedEmployee] = useState<
    ReducedEmployee | undefined
  >();
  const [amount, setAmount] = useState<number | undefined>();

  const [reasonCategory, setReasonCategory] = useState<string | undefined>();
  const [reason, setReason] = useState<string | undefined>();

  const ids = account.controls.payments.allowedTargetPlans;
  const queryString =
    ids?.map((id) => `planIds=${encodeURIComponent(id)}`).join("&") || "";
    
  const employees = useCustomQuery<Pagination<ReducedEmployee>>({
    endpoint: `/employers/${userInfoData?.employerId}/employees?${queryString}`,
    options: {
      enabled: !!userInfoData
    }
  });

  const mutation = useMutation({
    mutationFn: async () => {
      const response = await callApi(
        `${config?.API_URL}/payments`,
        "POST",
        {
          senderAccountId: account.id,
          receiverEmployeeId: selectedEmployee?.id,
          amount: {
            amount: amount
          },
          reasonCategory,
          reason
        },
        getToken
      );

      if (response && !response.ok)
        throw new Error(`There was a problem sending this payment.`);
    },
    onSuccess: () => {
      toast.success(`Payment sent successfully`);
      setSelectedEmployee(undefined);
      setAmount(undefined);
      setReasonCategory(undefined);
      setReason(undefined);
      accounts.refetch();
      transactions.refetch();
      navigate("/");
    },
    onError: (error: Error) => {
      console.error(error.message);
      setError(error.message);
    }
  });

  const handleSubmit = () => {
    if (!selectedEmployee) return setError("Employee must be selected");
    if (!amount) return setError("Amount is required");
    if (amount < 0) return setError("Amount must be more than $0");
    if (amount > account.availableBalance.amount)
      return setError(
        `Amount ${amount} exceeds available balance ${account.availableBalance.amount}`
      );
    if (!reasonCategory) return setError("Reason Category must be selected");
    if (!reason) return setError("Reason is required");
    if (reason.length > 100)
      return setError("Reason must be less than 100 characters");

    setError(undefined);
    mutation.mutate();
  };

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Typography>
        Available Balance: {formatCurrency(account.availableBalance.amount)}
      </Typography>
      <Autocomplete
        size="small"
        options={
          employees.data?.items.filter((e) => e.id !== account.employeeId) ?? []
        }
        getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
        value={selectedEmployee}
        onChange={(_, newValue) => {
          if (newValue) setSelectedEmployee(newValue);
        }}
        filterSelectedOptions
        aria-required
        renderInput={(params) => <TextField {...params} placeholder="Search" />}
      />

      <TextField
        value={amount}
        onChange={(e) => {
          const parsedValue = Number(e.currentTarget.value);
          if (Number.isNaN(parsedValue)) return;
          setAmount(+e.currentTarget.value);
        }}
        InputProps={{
          startAdornment: <InputAdornment position="start">$</InputAdornment>
        }}
        name="amount"
        label="Amount"
        placeholder="Amount"
        required
        fullWidth
        size="small"
      />

      <Autocomplete
        size="small"
        options={account.controls.payments.reasonCategories ?? []}
        getOptionLabel={(option) => option}
        value={reasonCategory}
        onChange={(_, newValue) => {
          if (newValue) setReasonCategory(newValue);
        }}
        filterSelectedOptions
        aria-required
        renderInput={(params) => (
          <TextField {...params} placeholder="Reason Category" />
        )}
      />

      <TextField
        value={reason}
        onChange={(e) => {
          setReason(e.currentTarget.value);
        }}
        name="reason"
        label="Reason"
        placeholder="Reason"
        required
        fullWidth
        size="small"
      />

      {error && <Typography color="error">{error}</Typography>}

      <LoadingButton
        loading={mutation.isLoading || accounts.isLoading}
        variant="contained"
        color="primary"
        onClick={handleSubmit}
      >
        Send
      </LoadingButton>
    </Box>
  );
};
