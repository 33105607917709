import {
  alpha,
  Avatar,
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  LinearProgress,
  Typography,
  useTheme
} from "@mui/material";
import { ChevronRight } from "react-feather";
import { useNavigate } from "react-router-dom";
import { EmployeeAccount, Plan, RenewalType } from "../models";
import { formatCurrency, formatDate } from "../utils/formatters";
import { useData } from "../utils/useData";
import { RewardAllocationLogoTooltip } from "./RewardAllocationLogoTooltip";

type AccountCardProps = {
  account: EmployeeAccount;
  plan: Plan;
  isSelected: boolean;
};

export const AccountCard = ({
  account,
  plan,
  isSelected
}: AccountCardProps) => {
  const { employer } = useData();
  const navigate = useNavigate();

  const theme: any = useTheme();

  const isPaymentAccount =
    account.controls.payments.isEnabled &&
    !account.controls.purchases.isEnabled;

  function handleClick() {
    navigate(`/account/${account.id}`);
  }

  return (
    <Card
      sx={{
        bgcolor: isSelected
          ? theme.palette.primary.main
          : alpha(theme.palette.primary.main, 0.1),
        color: isSelected
          ? theme.palette.primary.contrastText
          : alpha(theme.palette.primary.main, 0.5)
      }}
    >
      <CardActionArea>
        <CardContent>
          <Box
            sx={{ display: "flex", gap: 2, justifyContent: "space-between" }}
          >
            <Box>
              <Box display="flex">
                <Typography>{account.name}</Typography>
                {isPaymentAccount && (
                  <>
                    <RewardAllocationLogoTooltip />
                  </>
                )}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "baseline",
                  flexWrap: "wrap"
                }}
                columnGap={0.5}
              >
                <Typography variant="h4">
                  {formatCurrency(account.availableBalance.amount + 0)}
                </Typography>
                {plan.renewalOptions.type !== RenewalType.NEVER && (
                  <Typography>
                    (Expires {formatDate(account.renewalOptionsV2.renewalDate)})
                  </Typography>
                )}
              </Box>
            </Box>
            <Box display="flex" alignItems="center" onClick={handleClick}></Box>
            <Box display="flex" alignItems="center" onClick={handleClick}>
              {isPaymentAccount &&
              employer.data?.generalOptions.isRewardsEnabled ? (
                <Button
                  variant="outlined"
                  color="secondary"
                  sx={{
                    color: isSelected
                      ? "white"
                      : alpha(theme.palette.primary.main, 0.5),
                    borderColor: isSelected
                      ? "white"
                      : alpha(theme.palette.primary.main, 0.5),
                    "&:hover": {
                      borderColor: isSelected
                        ? "white"
                        : alpha(theme.palette.primary.main, 0.5)
                    }
                  }}
                >
                  Send Recognition
                </Button>
              ) : (
                <Avatar
                  sx={{
                    bgcolor: isSelected
                      ? theme.palette.primary.dark
                      : alpha(theme.palette.primary.main, 0.5),
                    borderRadius: "4px"
                  }}
                >
                  <ChevronRight
                    color={
                      isSelected
                        ? theme.palette.primary.main
                        : theme.palette.primary.lighter
                    }
                    size={35}
                  />
                </Avatar>
              )}
            </Box>
          </Box>
          <LinearProgress
            value={
              (100 *
                (account.startingBalance.amount -
                  account.availableBalance.amount)) /
              account.startingBalance.amount
            }
            variant="determinate"
            color="primary"
            sx={{
              height: 8,
              borderRadius: 4,
              marginTop: 1,
              "& .MuiLinearProgress-bar": {
                backgroundColor: isSelected
                  ? "primary.light"
                  : alpha(theme.palette.primary.main, 0.2)
              }
            }}
          />
        </CardContent>
      </CardActionArea>
    </Card>
  );
};
