import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { SectionHeader } from "../../components/SectionHeader";
import { SendPayment } from "../../components/SendPayment";
import { TransactionList } from "../../components/TransactionList";
import { EmployeeAccount } from "../../models";
import { sortDate } from "../../utils/date";
import { useData } from "../../utils/useData";

export const Account = () => {
  const { accountId } = useParams();
  const { accounts, transactions, employer } = useData();

  const [account, setAccount] = useState<EmployeeAccount | undefined>();
  const [isPaymentAccount, setIsPaymentAccount] = useState(false);

  const accountTransactions = transactions.data?.items
    .filter(
      (transaction) =>
        transaction?.accountId === accountId ||
        transaction?.charges?.find((c) => c.accountId === accountId)
    )
    ?.sort((a, b) => sortDate({ dateA: b.timestamp, dateB: a.timestamp }));

  useEffect(() => {
    if (!accounts.data?.items) return;

    let foundAccount = accounts.data?.items.find((a) => a.id === accountId);
    if (!foundAccount) return;

    setAccount(foundAccount);

    setIsPaymentAccount(
      foundAccount?.controls.payments.isEnabled &&
        !foundAccount?.controls.purchases.isEnabled
    );
  }, [accountId, accounts]);

  if (!accountId || !account) return <></>;

  return (
    <Grid container spacing={2}>
      {isPaymentAccount && employer.data?.generalOptions.isRewardsEnabled && (
        <Grid item xs={12}>
          <SectionHeader text="Recognition" showBackButton={true} />
          <SendPayment account={account} />
        </Grid>
      )}

      <Grid item xs={12}>
        <SectionHeader
          text="Transaction history"
          showBackButton={isPaymentAccount ? false : true}
        />
        <TransactionList transactions={accountTransactions ?? []} />
      </Grid>
    </Grid>
  );
};
